import { TextField, Box, Button } from "@mui/material";
import { useForm } from "@formspree/react";
import FormMessage from "./FormMessage";

function ContactForm() {
  const [state, handleSubmit] = useForm("moqzgaqd");
  if (state.succeeded) {
    return <FormMessage />;
  }
  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "850px",
          m: "auto",
          my: 2,
          gap: "2rem",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", gap: "2rem" }}
        >
          <TextField
            required
            name="firstname"
            id="outlined-required"
            label="First Name"
            placeholder="First Name"
            fullWidth
          />
          <TextField
            required
            name="lastname"
            id="outlined-required"
            label="Last Name"
            placeholder="Last Name"
            fullWidth
          />
        </Box>
        <Box>
          <TextField
            required
            name="email"
            type="email"
            label="Email"
            placeholder="Email"
            fullWidth
          />
        </Box>
        <Box>
          <TextField
            required
            name="message"
            id="outlined-required"
            label="Message"
            placeholder="Message"
            fullWidth
            multiline
            rows={8}
          />
        </Box>
        <Button variant="contained" color="secondary" type="submit">
          Submit
        </Button>
      </Box>
    </form>
  );
}
export default ContactForm;

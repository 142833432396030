import { Container, Typography, Box, Divider } from "@mui/material";

function About() {
  return (
    <div>
      <Container>
        <Typography variant="h5" py={3}>
          NEBULA ENGINEERING
        </Typography>
        <Box p={2}>
          <img src="/img/main_img.jpeg" alt="main" width={"100%"} />
        </Box>
        <Typography variant="body1" m={2}>
          Nebula Engineering is a New York based engineering consulting firm,
          striving to provide our clients with exceptional engineering service.
          We believe a good design should be not only accountable and precise,
          but also smart and agile. Our ultimate goal is to grow the success of
          our clients.
        </Typography>
        <Typography variant="body1" m={2}>
          Nebula Engineering focuses on electrical design and specializes in
          Traction Power. We have extensive experience working with New York and
          New Jersey area public transit agencies, as well as MBTA, SEPTA, DART,
          RTD and VRE on traction power projects. We have also worked with
          Department of Transportation and developers on both public and private
          development projects.​
        </Typography>
        <Typography variant="body1" m={2}>
          Nebula Engineering is DBE and MBE certified with following states and
          agencies.
        </Typography>
        <Divider />
        <Typography variant="h6" mt={2} mx={2}>
          DBE
        </Typography>
        <Typography variant="body1" mx={2} mb={2}>
          NY, NJ, PA, Mass, WMATA
        </Typography>
        <Typography variant="h6" mt={2} mx={2}>
          MBE
        </Typography>
        <Typography variant="body1" mx={2} mb={2}>
          NYS, NYC, PANYNJ
        </Typography>
      </Container>
    </div>
  );
}

export default About;

import { Divider, Box, Typography, Container } from "@mui/material";

function Footer() {
  return (
    <Box sx={{ textAlign: "center" }}>
      <Divider />
      <Container>
        <Typography m={2}>Copyright © 2023 Nebula Engineering</Typography>
      </Container>
    </Box>
  );
}
export default Footer;

import logo from "./logo.svg";
import About from "./pages/About";
import ContactUs from "./pages/ContactUs";
import Electrical from "./pages/Electrical";
import TractionPower from "./pages/TractionPower";
import Navbar from "./components/NavBar";
import { Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import "./App.css";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <ThemeProvider theme={theme}>
        <Navbar />
        <Routes>
          <Route path="/" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/electrical" element={<Electrical />} />
          <Route path="/tractionpower" element={<TractionPower />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;

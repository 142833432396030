import { createTheme } from "@mui/material/styles";
export const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
      dark: "#4486b3",
      light: "#a6cde7",
      contrastText: "#024572",
    },
    secondary: {
      main: "#024572",
      dark: "#4486b3",
      light: "#a6cde7",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
});

import { Container, Typography, Box, Divider } from "@mui/material";

function Electrical() {
  return (
    <div>
      <Container>
        <Typography variant="h5" py={3}>
          ELECTRICAL ENGINEERING
        </Typography>
        <Box p={2}>
          {" "}
          <img src="/img/electrical.jpeg" alt="main" width={"100%"} />
        </Box>
        <Typography variant="h6" m={2}>
          Nebula Engineering provides electrical design and construction
          service, including:
        </Typography>

        <Typography variant="body1" mx={2} my={1}>
          Medium/Low Voltage Power Distribution Systems Design
        </Typography>
        <Typography variant="body1" mx={2} my={1}>
          Electrical System Modeling and Analysis with SKM Power Tools (Short
          Circuit Calculation, Protective Device Coordination, Arc Flash
          Analysis)
        </Typography>
        <Typography variant="body1" mx={2} my={1}>
          Data/telecommunications and Security Design
        </Typography>
        <Typography variant="body1" mx={2} my={1}>
          Lighting Calculations and Design (Roadway, Parking Lot, Yard Facility,
          Interior)
        </Typography>
        <Typography variant="body1" mx={2} my={1}>
          Electrical Cost Estimate
        </Typography>
        <Typography variant="body1" mx={2} my={1}>
          Utility Coordination
        </Typography>
        <Typography variant="body1" mx={2} my={1}>
          Submittals and RFIs Review
        </Typography>
        <Divider />
        <Typography variant="h6" mt={2} mx={2}>
          DBE
        </Typography>
        <Typography variant="body1" mx={2} mb={2}>
          NY, NJ, PA, Mass, WMATA
        </Typography>
        <Typography variant="h6" mt={2} mx={2}>
          MBE
        </Typography>
        <Typography variant="body1" mx={2} mb={2}>
          NYS, NYC, PANYNJ
        </Typography>
      </Container>
    </div>
  );
}

export default Electrical;

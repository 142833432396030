import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';

function FormMessage() {
  
  return (
    <Box sx={{ maxWidth:'600px', m:"auto", height: "75vh", display:"flex", flexDirection:"column", alignItems:"center", gap:"2rem", justifyContent:"center" }}>
      <Typography variant="h6" align="center" mt={5}>Thanks for Contacting us! We wil get back to you soon!</Typography>
      <Button m={2} href='/' variant="contained" color="secondary" size="small" disableElevation endIcon={<HomeIcon />} >
        Back to Home
      </Button>
    </Box>
  );
}
export default FormMessage;

import { Container, Typography, Box, Divider } from "@mui/material";

function TractionPower() {
  return (
    <div>
      <Container>
        <Typography variant="h5" py={3}>
          TRACTION POWER
        </Typography>
        <Box p={2}>
          {" "}
          <img src="/img/power.jpeg" alt="main" width={"100%"} />
        </Box>
        <Typography variant="h6" m={2}>
          Nebula Engineering specializes in Traction Power for transit systems.
          Our Traction Power expertise consists of:
        </Typography>

        <Typography variant="body1" mx={2} my={1}>
          AC/DC Traction Power Substation Design
        </Typography>
        <Typography variant="body1" mx={2} my={1}>
          Contact Rail (3rd Rail) System
        </Typography>
        <Typography variant="body1" mx={2} my={1}>
          Overhead Catenary System (OCS)
        </Typography>
        <Typography variant="body1" mx={2} my={1}>
          Transit Load Flow Analysis
        </Typography>
        <Typography variant="body1" mx={2} my={1}>
          Wayside Power and Energy Storage System
        </Typography>

        <Divider />
        <Typography variant="h6" mt={2} mx={2}>
          DBE
        </Typography>
        <Typography variant="body1" mx={2} mb={2}>
          NY, NJ, PA, Mass, WMATA
        </Typography>
        <Typography variant="h6" mt={2} mx={2}>
          MBE
        </Typography>
        <Typography variant="body1" mx={2} mb={2}>
          NYS, NYC, PANYNJ
        </Typography>
      </Container>
    </div>
  );
}

export default TractionPower;

import { Container, Typography, Box, Divider } from "@mui/material";
import ContactForm from "../components/ContactForm";

function ContactUs() {
  return (
    <div>
      <Container>
        <Typography variant="h5" py={3}>
          CONTACT US
        </Typography>
        <ContactForm />
      </Container>
    </div>
  );
}

export default ContactUs;
